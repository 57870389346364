import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getCommonStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  box: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  quickLinkGrid: css({
    display: 'grid',
    gridTemplateColumns: 'max-content',
    '@media(min-width: 1100px)': {
      gridTemplateColumns: 'max-content max-content',
    },
    gap: theme.spacing(2),
  }),
  indentedBoxContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  }),
  appPluginLinkContainer: css({
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: theme.spacing(2),
    alignItems: 'center',
  }),
  secondaryText: css({
    color: theme.colors.text.secondary,
  }),
});
