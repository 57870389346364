export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
    apiKey: 'data-testid ac-api-key',
    apiUrl: 'data-testid ac-api-url',
    submit: 'data-testid ac-submit-form',
  },
  quickLink: {
    link: 'quick-link--link',
    disabledLink: 'quick-link--disabled-link',
  },
  suspense: {
    loading: 'suspense-loading',
  },
};
