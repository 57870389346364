import React from 'react';
import { css, cx } from '@emotion/css';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { testIds } from '@/components/testIds';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    alignSelf: 'flex-start',
    alignItems: 'stretch',
    display: 'flex',
  }),
  disabled: css({
    cursor: 'not-allowed',
    color: theme.colors.text.disabled,
    backgroundColor: theme.colors.action.disabledBackground,
  }),
  disabledIcon: css({
    fill: theme.colors.text.disabled,
  }),
  quickLink: css({
    backgroundColor: theme.colors.background.primary,
    cursor: 'pointer',
    display: 'flex',
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontSize: theme.typography.fontSize,
    alignItems: 'center',
  }),
  iconContainer: css({
    display: 'inline-flex',
  }),
  quickLinkIcon: css({
    display: 'inline-flex',
    color: theme.colors.text.link,
    marginRight: theme.spacing(2),
    alignItems: 'center',
  }),
});

type Props = {
  className?: string;
  children: React.ReactNode;
  href?: string;
  icon?: IconName;
  target?: string;
  rel?: string;
};
const Link = ({ className, children, href, icon = 'apps', target, rel }: Props) => {
  const styles = useStyles2(getStyles);
  if (!href) {
    return (
      <Tooltip content={'This dashboard has either been removed or you don’t have permission to view it'}>
        <span className={cx(className, styles.quickLink, styles.disabled)} data-testid={testIds.quickLink.disabledLink}>
          <span className={styles.iconContainer}>
            <Icon name={icon} size="lg" className={cx(styles.quickLinkIcon, styles.disabledIcon)} />
          </span>
          {children}
        </span>
      </Tooltip>
    );
  }

  return (
    <a
      className={cx(className, styles.quickLink)}
      data-testid={testIds.quickLink.link}
      href={href}
      target={target}
      rel={rel}
    >
      <span className={styles.iconContainer}>
        <Icon name={icon} size="lg" className={styles.quickLinkIcon} />
      </span>
      {children}
    </a>
  );
};

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
};
const Container = ({ children, className }: ContainerProps) => {
  const styles = useStyles2(getStyles);

  return <div className={cx(styles.container, className)}>{children}</div>;
};

const ExternalLink = ({ href, className, children }: Props) => {
  return (
    <QuickLink.Link className={className} href={href} icon={'external-link-alt'} target={'_blank'} rel="noreferrer">
      {children}
    </QuickLink.Link>
  );
};

export const QuickLink = {
  Container,
  Link,
  ExternalLink,
};
